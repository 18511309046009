import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

const FormWrapper = ({
                         onSubmit,
                         defaultValues = {},
                         validationSchema,
                         children,
                         className = '',
                         resetOnSubmit = true,
                     }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues,
        resolver: validationSchema,
    });

    const handleFormSubmit = async (data) => {
        await onSubmit(data);
        if (resetOnSubmit) reset(); // Reset form after submission if needed
    };

    return (
        <form className={className} onSubmit={handleSubmit(handleFormSubmit)}>
            {React.Children.map(children, child =>
                React.cloneElement(child, { register, errors })
            )}
        </form>
    );
};

FormWrapper.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    validationSchema: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    resetOnSubmit: PropTypes.bool,
};

export default FormWrapper;
